//site
$primary-h: 210 !default;
$primary-s: 100 !default;
$primary-b: 40 !default;
$primary: #0066cc;

//$secondary;
$tertiary: #f8ea29;

//colors
$gray-900: #212529;
$body-color: $gray-900;

//font / texts
$tertiary-text: $body-color;

//headers
$header-slim-bg-color: $tertiary;
$header-slim-text-color: $body-color;

//$header-text-color: #2a3036;

//$headings-color: $primary;
//$semibold: 600;
//$link-color: hsl(196deg, 100%, 30%);
