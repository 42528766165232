.block.listing {
  .bg-primary {
    background-color: $primary !important;
    h2 {
      color: #fff;
    }
    .link-button {
      .btn.btn-primary {
        background-color: $tertiary;
        color: $body-color;
      }
    }
    .ribbon-card-template {
      .flag-icon {
        background: $tertiary;
      }
    }
    .squares-image-template {
      .box {
        .title {
          color: $body-color;
        }
        &:hover:before {
          background: $tertiary;
        }
        &:before {
          background-color: $tertiary;
          opacity: 0.7;
        }
      }
    }
  }
  .bg-tertiary {
    background-color: $tertiary !important;
  }
  .transparent {
    background-color: none;
  }
}
