.it-footer {
  .it-footer-main {
    .it-brand-wrapper {
      a {
        .icon {
          width: auto;
        }
      }
    }
  }
}
